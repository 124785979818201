<template>
  <v-container fluid class="pa-0">
    <v-row justify="space-between">
      <v-col cols="12" sm="6" md="4">
        <h1>{{ heading }}</h1>
        <p class="text-body-2 text-sm-body-1" v-if="subHeading">{{ subHeading }}</p>
      </v-col>
      <v-col cols="12" md="8" :lg="largeContent ? '8' : '6'" v-if="slotIsFilled">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    heading: {
      type: String,
      required: true
    },
    subHeading: {
      type: String
    },
    largeContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slotIsFilled () {
      return !!this.$slots.default
    }
  }
}
</script>
