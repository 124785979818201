import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'
import { mapGetters } from 'vuex'

import ComponentGuard from '@/components/ComponentGuard'

export default {
  components: {
    ComponentGuard
  },
  methods: {
    $tp (key, values = {}) {
      return this.$t(`${this.pageTranslationKey}.${key}`, values)
    }
  },
  computed: {
    ...mapGetters('keycloak', ['isAdmin', 'isManager', 'isClerk']),
    page () {
      return this.$route?.name ?? 'default'
    },
    pageTitle () {
      return this.$tp('meta.title')
    },
    pageHeading () {
      return this.$tp('meta.heading')
    },
    pageSubHeading () {
      return this.$tp('meta.subHeading')
    },
    pageTranslationKey () {
      return `pages.${this.page}`
    }
  },
  provide () {
    return {
      page: this.page,
      pageTranslationKey: this.pageTranslationKey,
      $tp: this.$tp,
      vbp: this.$vuetify.breakpoint
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const roles = get(to.meta, 'roles', [])
      if (roles.length > 0) {
        const hasAccess = roles.reduce((acc, role) => {
          const methodName = `is${upperFirst(role)}`
          return acc || vm[methodName]
        }, false)
        if (!hasAccess) {
          next({ name: 'home' })
        } else {
          next()
        }
      } else {
        next()
      }
    })
  }
}
