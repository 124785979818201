<template>
  <div v-if="show">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'ComponentGuard',
  props: {
    roles: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapGetters('keycloak', ['isAdmin', 'isManager', 'isClerk']),
    show () {
      return this.roles
        .reduce((show, role) => {
          const methodName = `is${upperFirst(role)}`
          return show || this[methodName]
        }, false)
    }
  }
}
</script>
