export default {
  methods: {
    $td (key, defaultValue) {
      if (this.$te(key)) {
        return this.$t(key)
      }

      return defaultValue
    }
  },
  provide () {
    return {
      $td: this.$td
    }
  }
}
